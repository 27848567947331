<template>
  <div>
    <v-dialog v-model="popupOpen" max-width="80%" max-Height="100%" persistent>
      <v-card>
        <v-card-text class="iframeContainer">
          <iframe title="" v-if="docUrl" :src="docUrl">
          </iframe>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="closePopup">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
// import pdf from 'vue-pdf';

export default {
  name: 'TermsofService',
  data() {
    return {
      popupOpen: false,
      docUrl: '',
    };
  },
  watch: {
    termsService() {
      if (this.termsService) {
        this.loadPdfData();
        this.popupOpen = true;
      } else {
        this.popupOpen = false;
      }
    },
  },
  computed: {
    ...mapState('helper', ['termsService']),
  },
  methods: {
    ...mapMutations('helper', ['setTermsServices']),
    closePopup() {
      this.setTermsServices(false);
    },
    loadPdfData() {
      const pdfData = '/assets/TermsOfServices.pdf#zoom=120#toolbar=0';
      this.docUrl = pdfData;
    },
  },
  beforeDestroy() {
    this.setTermsServices(false);
    this.popupOpen = false;
  },
};
</script>
<style>
.iframe-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 75vh;
}
</style>

<template>
  <v-menu
    bottom
    offset-y
    z-index="9999"
    transition="slide-y-transition"
  >
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon
              v-text="'$help'"
            ></v-icon>
          </v-btn>
        </template>
        <span class="text-center">
          {{ $t('helper.help') }}
        </span>
      </v-tooltip>
    </template>
    <v-list dense>
      <template v-for="(item, index) in items">
        <v-subheader
          :key="index"
          v-if="item.header"
          class="mb-0 pb-0 text-uppercase"
          v-text="$t(`help.${item.header}`)"
        ></v-subheader>
        <v-divider
          :key="index"
          class="pb-1"
          v-else-if="item.divider"
        ></v-divider>
        <v-list-item
          v-else
          :key="index"
          @click="action(item.action)"
        >
          <v-list-item-title
            v-text="$t(`help.${item.title}`)"
          ></v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <v-list dense class="py-0">
      <v-subheader class="mb-0 pb-0 text-uppercase">
        {{ $t('help.version') }}
      </v-subheader>
      <v-list-item>
        <v-list-item-title>
          v{{ version }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'InfinityHelp',
  data() {
    return {
      items: [
        {
          header: 'help',
        },
        {
          title: 'support',
          icon: '$support',
          action: 'openSupport',
        },
        {
          title: 'submitticket',
          action: 'openSubmitTicket',
        },
        {
          divider: true,
        },
        {
          header: 'legal',
        },
        {
          title: 'terms',
          action: 'openTerms',
        },
        {
          divider: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('helper', ['version']),
  },
  methods: {
    ...mapMutations('helper', ['setTermsServices']),
    action(actionName) {
      this[`${actionName}`]();
    },
    openSupport() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('nonce', 'ShopWorx Support Portal');
      script.src = 'https://desk.zoho.com/portal/api/web/inapp/641008000008495001?orgId=758264048';
      script.defer = true;
      document.head.appendChild(script);
    },
    openSubmitTicket() {
      window.open('https://entrib.zohodesk.com/portal/en/newticket?departmentId=641008000000006907', '_blank');
    },
    openTerms() {
      this.setTermsServices(true);
    },
  },
};
</script>

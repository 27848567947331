<template>
  <v-footer
    app
    dark
    inset
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-text class="py-1 white--text text-right">
        <span v-html="text"></span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'InfinityFooter',
  props: ['text'],
};
</script>

<template>
  <v-list nav dense :rounded="expandOnHover" :shaped="!expandOnHover">
    <template v-for="(item, index) in drawerItems">
      <v-subheader
        :key="index"
        v-if="item.header && !expandOnHover"
        class="text-uppercase"
        v-text="$t(`modules.${item.header}`)"
      ></v-subheader>
      <v-divider
        :key="index"
        class="my-1"
        v-else-if="item.header && expandOnHover"
      ></v-divider>
      <v-list-item
        exact
        :key="index"
        v-else-if="item.to === 'reports'"
        :title="$t(`modules.${item.title}`)"
        :to="{ name: item.to, query: { id: item.title } }"
        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title
          v-text="$t(`modules.${item.title}`)"
        ></v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :key="index"
        v-else-if="item.external"
        target="_blank"
        :href="item.to"
        :title="
          item.to.toLowerCase().includes('custom')
            ? item.description
            : $t(`modules.${item.title}`)
        "
        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title
          v-text="
            item.to.toLowerCase().includes('custom')
              ? item.description
              : $t(`modules.${item.title}`)
          "
        ></v-list-item-title>
        <v-list-item-action v-if="item.external">
          <v-icon small class="mb-1">mdi-open-in-new</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        link
        :key="index"
        v-else-if="item.http"
        target="_blank"
        :href="item.to"
        :title="item.description"
        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.description"></v-list-item-title>
        <v-list-item-action>
          <v-icon small class="mb-1">mdi-open-in-new</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        link
        v-else
        :key="index"
        :to="{ name: item.title }"
        @click="setActiveApp(item)"
        :title="$t(`modules.${item.title}`)"
        :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title
          v-text="$t(`modules.${item.title}`)"
        ></v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AppList',
  props: ['drawerItems', 'expandOnHover'],
  methods: {
    ...mapMutations('webApp', ['setActiveAppId']),
    setActiveApp(item) {
      this.setActiveAppId(item.id);
      localStorage.setItem('appId', item.id);
    },
  },
};
</script>

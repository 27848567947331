var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"nav":"","dense":"","rounded":_vm.expandOnHover,"shaped":!_vm.expandOnHover}},[_vm._l((_vm.drawerItems),function(item,index){return [(item.header && !_vm.expandOnHover)?_c('v-subheader',{key:index,staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.$t(`modules.${item.header}`))}}):(item.header && _vm.expandOnHover)?_c('v-divider',{key:index,staticClass:"my-1"}):(item.to === 'reports')?_c('v-list-item',{key:index,attrs:{"exact":"","title":_vm.$t(`modules.${item.title}`),"to":{ name: item.to, query: { id: item.title } },"color":_vm.$vuetify.theme.dark ? 'primary' : 'secondary'}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(`modules.${item.title}`))}})],1):(item.external)?_c('v-list-item',{key:index,attrs:{"link":"","target":"_blank","href":item.to,"title":item.to.toLowerCase().includes('custom')
          ? item.description
          : _vm.$t(`modules.${item.title}`),"color":_vm.$vuetify.theme.dark ? 'primary' : 'secondary'}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(
          item.to.toLowerCase().includes('custom')
            ? item.description
            : _vm.$t(`modules.${item.title}`)
        )}}),(item.external)?_c('v-list-item-action',[_c('v-icon',{staticClass:"mb-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e()],1):(item.http)?_c('v-list-item',{key:index,attrs:{"link":"","target":"_blank","href":item.to,"title":item.description,"color":_vm.$vuetify.theme.dark ? 'primary' : 'secondary'}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.description)}}),_c('v-list-item-action',[_c('v-icon',{staticClass:"mb-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1):_c('v-list-item',{key:index,attrs:{"link":"","to":{ name: item.title },"title":_vm.$t(`modules.${item.title}`),"color":_vm.$vuetify.theme.dark ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.setActiveApp(item)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(`modules.${item.title}`))}})],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }